import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/utils/http-service.service';
import { Orgao } from './models/Orgao';
import { UnidadeAdministrativaSei } from './models/UnidadeAdministrativaSei';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(
    private service: HttpService
  ) {
  }
  listarOrgaos(): Observable<Orgao[]>{
    return this.service.sendGet('admin/orgaos');
  }
  listarUnidadesOrgao(codOrgao: number): Observable<UnidadeAdministrativaSei[]>{
    return this.service.sendGet(`admin/unidades?filter[codOrgao]=${codOrgao}`);
  }
  getProcesso(unidadeAdm, protocolo){
    return this.service.sendGet('admin/sei/processos', {unidade: unidadeAdm, protocolo: protocolo});
  }
  listarMunicipios(): Observable<any[]>{
    return this.service.sendGet('listarMunicipios');
  }
}
