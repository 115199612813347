<div class="table-container overflow-auto text-nowrap">
  <div
    class="p-2 text-light bg-secondary d-flex justify-content-between align-items-center"
    *ngIf="title"
  >
    <div class="d-flex">
      <mat-icon *ngIf="icone">{{ icone }}</mat-icon>
      <h5 class="ml-3 mb-0">{{ title }}</h5>
    </div>
    <div class="d-flex gap-3">
      <ng-content select="[title-area]"></ng-content>
    </div>
  </div>
  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    (matSortChange)="announceSortChange($event)"
  >
    <ng-container
      *ngFor="let column of columns"
      matColumnDef="{{ column.def }}"
    >
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ column.header }}
      </th>
      <ng-container
        *ngIf="column.type === 'normal' || column.type === undefined"
      >
        <td mat-cell *matCellDef="let row">{{ row[column.def] }}</td>
      </ng-container>
      <ng-container *ngIf="column.type === 'elipsis'">
        <td mat-cell *matCellDef="let row">
          <span class="text-elipsis">{{ row[column.def] }}</span>
        </td>
      </ng-container>
      <ng-container *ngIf="column.type === 'link'">
        <td mat-cell *matCellDef="let row">
          <a
            (click)="$event.stopPropagation()"
            [href]="addHttpPrefix(row[column.def].href)"
            target="_blank"
            rel="noopener noreferrer"
            >{{ removeHttpPrefix(row[column.def].link) }}</a
          >
        </td>
      </ng-container>
      <ng-container *ngIf="column.type === 'status'">
        <td mat-cell *matCellDef="let row" class="status-table-row">
          <span
            class="rounded-pill p-2 my-border text-elipsis"
            [matBadge]="
              row[column.def].badge ? row[column.def].badge.label : ''
            "
            [matBadgeColor]="
              row[column.def].badge ? row[column.def].badge.color : 'primary'
            "
            matTooltipClass="example-tooltip-conf"
            matTooltipPosition="above"
            [matTooltip]="
              row[column.def].tooltip ? row[column.def].tooltip : ''
            "
            [ngStyle]="{
              'background-color': row[column.def].color
                ? row[column.def].color + '88'
                : '#fff',
              'border-color': row[column.def].color
                ? row[column.def].color
                : '#fff'
            }"
            >{{
              row[column.def].label ? row[column.def].label : row[column.def]
            }}</span
          >
        </td>
      </ng-container>
      <ng-container *ngIf="column.type === 'statusMultiple'">
        <td mat-cell *matCellDef="let row">
          <span
            *ngFor="let status of row[column.def]"
            matTooltipClass="example-tooltip-conf"
            matTooltipPosition="above"
            class="multiple-status-pill rounded-pill p-2"
            [matTooltip]="status.tooltip ? status.tooltip : ''"
            [ngStyle]="{
              'background-color': status.color ? status.color + '88' : '#fff'
            }"
            >{{ status.label ? status.label : status }}</span
          >
        </td>
      </ng-container>
      <ng-container *ngIf="column.type === 'actionBtns'">
        <td mat-cell *matCellDef="let row">
          <ng-container *ngFor="let btn of row[column.def]">
            <button
              mat-icon-button
              class="mat-elevation-z1 m-1"
              color="basic"
              (click)="btn.fn(row)"
              [matTooltip]="btn?.tooltip"
              matTooltipClass="example-tooltip-conf"
              matTooltipPosition="above"
              [disabled]="btn.disabled || loading ? true : false"
              [ngStyle]="{
                display: btn.hidden ? btn.hidden : false ? 'none' : 'unset'
              }"
            >
              <mat-icon>{{ btn.icon }}</mat-icon>
            </button>
          </ng-container>
        </td>
      </ng-container>
      <ng-container *ngIf="column.type === 'checkBox'">
        <td mat-cell class="checkbox-cell" *matCellDef="let row">
          <mat-checkbox
            [formControl]="row[column.def]"
            color="primary"
          ></mat-checkbox>
        </td>
      </ng-container>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      [routerLink]="linkTableRow ? linkTableRow + row.id : null"
      (click)="elementRowSelected(row)"
      class="{{ linkTableRow || activeRowClick ? 'link-tr' : null }}"
      [ngClass]="row.trClass"
      [ngClass]="{ compact: compact }"
    ></tr>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">
        {{ loading ? "Carregando.." : "Nada encontrado.." }}
      </td>
    </tr>
  </table>
  <app-input-loading
    [isLoading]="loading"
    [legacyInput]="true"
    [relativePosition]="true"
  ></app-input-loading>
  <mat-paginator
    *ngIf="pageSizeOptions"
    [ngClass]="{ 'd-none': data?.length < pageSizeOptions[0] }"
    [pageSizeOptions]="pageSizeOptions"
  ></mat-paginator>
</div>
