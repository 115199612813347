import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { Transgressao } from 'src/app/modules/admin/models/Trangressao';
import { EnquadramentoService } from '../enquadramento-select.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-enquadramento-select-dialog',
  templateUrl: './enquadramento-select-dialog.component.html',
  styleUrls: ['./enquadramento-select-dialog.component.css']
})
export class EnquadramentoSelectDialogComponent implements OnInit, AfterViewInit, OnDestroy {

  displayedColumns: string[] = ['select', 'artigo', 'inciso', 'descricao', 'penalidade'];
  dataSource: MatTableDataSource<any>;
  selection: SelectionModel<any>;

  artigoFilter = new UntypedFormControl(null);
  descricaoFilter = new UntypedFormControl(null);

  artigoLoading = false;
  artigos: any[];

  selectionSubscriber: Subscription;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(
    private enqudramentoService: EnquadramentoService,
    public dialogRef: MatDialogRef<EnquadramentoSelectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.dataSource = new MatTableDataSource<Transgressao>(this.data.enquadramentos);
    this.selection = new SelectionModel<Transgressao>(true, this.data.enquadramentos.filter(enquadramento => this.data.form.value?.find(element => element.id === enquadramento.id) ? 1 : 0));
    if (this.data.unique) {
      this.displayedColumns = ['artigo', 'inciso', 'descricao', 'penalidade'];
    }
    this.selectionSubscriber = this.selection.changed.subscribe(value => {
      this.data.form.setValue(value.source.selected);
    });
    this.artigoFilter.valueChanges
      .subscribe(() => {
        this.updateFilters();
      });
    this.descricaoFilter.valueChanges
      .subscribe(() => {
        this.updateFilters();
      });
  }

  ngOnInit(): void {
    this.artigoFilter.disable();
    this.artigoLoading = true;
    this.enqudramentoService.getArtigos(this.data.forTac)
      .pipe(take(1))
      .subscribe((artigos: any[]) => {
        this.artigos = artigos;
        this.artigoFilter.enable();
        this.artigoLoading = false;
      },
        error => {
          console.error(error);
          this.artigoFilter.enable();
          this.artigoLoading = false;
        });
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy(): void {
    this.selectionSubscriber.unsubscribe();
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  updateFilters() {
    const filteredData = this.data.enquadramentos.filter(enquadramento => {
      let test = true;
      let test2 = true;
      this.artigoFilter.value?.split(" ").forEach((subStr: string) => test = enquadramento.artigo.descricao.toLowerCase().includes(subStr.toLowerCase()));
      this.descricaoFilter.value?.split(" ").forEach((subStr: string) => test2 = enquadramento.descricao.toLowerCase().includes(subStr.toLowerCase()));
      return test && test2;
    });
    this.dataSource = new MatTableDataSource<Transgressao>(filteredData);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource.data);
  }
  handleRowClick(row){
    if(this.data.unique){
      this.selection.clear();
      this.selection.select(row);
    } else {
      this.selection.toggle(row);
    }
  }
  deleteTransgressao(tr) {
    this.selection.deselect(this.data.enquadramentos.find(enquadramento => enquadramento.id === tr.id))
  }
}
