import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { AnexoService } from 'src/app/components/anexo/anexo.service';
import { Anexo } from 'src/app/modules/admin/models/Anexo';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { HelperService } from 'src/app/utils/helper.service';

@Component({
  selector: 'app-anexo',
  templateUrl: './anexo.component.html',
  styleUrls: ['./anexo.component.css']
})
export class AnexoComponent implements OnInit, OnChanges, OnDestroy {

  anexos: UntypedFormControl = new UntypedFormControl([]);;
  @Input() inAnexos;
  @Input() lbButton = "Anexar";
  @Input() required = false;
  @Input() disabled = false;
  @Input() maxFileSize = 10000000; //10mb
  @Input() allowedTypes = "image/png, image/jpeg, image/jpg, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword";
  @Input() tooltip: string;
  @Output() selectedAnexo: EventEmitter<Anexo> = new EventEmitter();

  msgError = false;
  anexosSubscriber: Subscription | null;

  constructor(
    public helper: HelperService,
    private anexoService: AnexoService,
    private spinner: NgxSpinnerService,
    private snackBar: SnackBarService
  ) {
  }

  ngOnInit(): void {
    this.anexos.valueChanges.subscribe(anexo => {
      this.selectedAnexo.emit(anexo);
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.inAnexos) {
      if (changes.inAnexos.currentValue) this.setAnexo(changes.inAnexos.currentValue);
    }
  }
  ngOnDestroy() {
    this.selectedAnexo.emit(this.anexos.value);
  }

  setAnexo(anexos) {
    if (anexos.id)
      anexos = [anexos];
    this.anexos.setValue(anexos);
    this.msgError = false;
  }

  public getAnexos() {
    return this.anexos.value;
  }

  public getAnexosFormData(anexoNome = "arquivo") {
    let formData: FormData = null;
    const anexos = this.anexos.value;
    if (anexos != null) {
      if (anexos.length > 0) {
        formData = new FormData();
        for (let i = 0; i < anexos.length; i++) {
          formData.append(anexoNome, anexos[i]);
        }
      }
    }
    return formData;
  }

  public validate() {
    if (this.getAnexosFormData() == null) {
      if (this.required) {
        this.msgError = true;
        return false;
      }
    } else {
      this.msgError = false;
      return true;
    }
  }

  public enviarAnexos(formDataAnexos = null): Promise<any> {
    // Se o anexo já existe (tem id), retornamos uma promise que resolve imediatamente com o id do anexo
    if (this.anexos.value[0].id) {
      return new Promise((resolve) => {
        resolve(this.anexos.value[0].id);
      });
    }
    if (formDataAnexos == null) {
      const formData = this.getAnexosFormData();
      if (formData != null) {
        this.msgError = false;
        return this.anexoService.upload(formData, {})
      } else {
        if (this.required)
          this.msgError = true;
      }
    } else {

      return this.anexoService.upload(formDataAnexos, {})
    }
  }
  visualizar(id) {
    this.spinner.show();
    this.anexoService.download(id)
      .subscribe(blob => {
        this.showAnexo(blob, blob.type)
        this.spinner.hide()
      }, (error: HttpErrorResponse) => {
        console.error(error);
        this.snackBar.showSnackBarError('Erro ao tentar visualizar o anexo');
        this.spinner.hide()
      })
  }
  deletar(id) {
    this.anexos.setValue([]);
  }

  showAnexo(data: any, type: string) {
    const blob = new Blob([data], { type: type });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }

}
