import { Injectable } from '@angular/core'
import { MatSnackBar, MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  constructor(private snackBar: MatSnackBar) { }

  showSnackBar(msg: string, action: string = '', panelClass: string = '', duration: number = 4000): MatSnackBarRef<TextOnlySnackBar> {
    return this.snackBar.open(msg, action, {
      duration: action === '' ? duration : undefined,
      horizontalPosition: "right",
      verticalPosition: "bottom",
      panelClass: panelClass
    });
  }
  showSnackBarError(msg: string): MatSnackBarRef<TextOnlySnackBar> {
    if (typeof msg === 'object') {
      if(Object.values(msg).length === 1) {
        this.showSnackBar(String(Object.values(msg)[0]), 'Fechar', 'msg-snack-error');
        return;
      }
      const reducedMsg = Object.values(msg)
        .reduce(
          (previousValue: string, currentValue: string) => {
            return (previousValue[0] + ' ') + currentValue[0]
          }
        );
        return this.showSnackBar(String(reducedMsg), 'Fechar', 'msg-snack-error');
    } else {
      return this.showSnackBar(String(msg), 'Fechar', 'msg-snack-error');
    }
  }
  showSnackBarSuccess(msg: string): MatSnackBarRef<TextOnlySnackBar> {
    return this.showSnackBar(msg, '', 'msg-snack-success');
  }
  showSnackBarWarning(msg: string): MatSnackBarRef<TextOnlySnackBar> {
    return this.showSnackBar(msg, 'Ok', 'msg-snack-warning');
  }
  showSnackBarInfo(msg: string): MatSnackBarRef<TextOnlySnackBar> {
    return this.showSnackBar(msg, 'Ok');
  }
}
